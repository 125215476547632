import axios, { AxiosResponse } from 'axios';
import { TCard } from '@types';
import { ECardType } from '../typescript/enums';
import { ICreateCard, IUpdateCard } from '../typescript/requests';

export const apiGetAllCards = (): Promise<AxiosResponse<TCard[]>> => {
    return axios.get('/cards')
}

export const apiGetCardsByCategory = (category: ECardType): Promise<AxiosResponse<TCard[]>> => {
    return axios.get(`/cards/${category}`)
}

export const apiGetCardById = (id: string): Promise<AxiosResponse<TCard>> => {
    return axios.get(`/cards/id/${id}`)
}

export const apiCreateCard = (data: ICreateCard): Promise<AxiosResponse<TCard>> => {
    return axios.post('/cards', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export const apiUpdateCard = ({ id, ...data }: IUpdateCard): Promise<AxiosResponse<TCard>> => {
    return axios.put(`/cards/${id}`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

export const apiDeleteCard = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`/cards/${id}`)
}
