import React, { useState } from 'react';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiGetUsersList, apiGetUsersStats } from '@api/dashboard';
import { Input } from '@components/input';
import { useDebounceValue } from 'usehooks-ts';

import StatisticCards from './components/home/statistic_cards';
import UserTable from './components/home/user_table';
import PaginationControls from './components/home/pagination_controls';

const Home = () => {
    const [pagination, setPagination] = useState<{ page: string; pageSize: string; sort: string }>({
        page: '0',
        pageSize: '100',
        sort: 'createdAt'
    });
    const [searchValue, setSearchValue] = useDebounceValue('', 500);

    const { data } = useApiQuery({
        method: apiGetUsersStats,
        isInitialRequest: true,
        isInterval: true
    });

    const { data: usersListData } = useApiQuery({
        method: apiGetUsersList,
        isInitialRequest: true,
        isInterval: true,
        data: { ...pagination, username: searchValue },
        deps: [pagination, searchValue]
    });

    const handlePagePrev = () => {
        if (usersListData) {
            setPagination((state) => ({
                ...state,
                page: Number(state.page) > 0 ? String(Number(state.page) - 1) : state.page
            }));
        }
    };

    const handlePageNext = () => {
        if (usersListData) {
            const { totalPages } = usersListData.metadata;
            setPagination((state) => ({
                ...state,
                page:
                    Number(state.page) === totalPages - 1
                        ? state.page
                        : String(Number(state.page) + 1)
            }));
        }
    };

    const handleChangeSort = (sort: string) => {
        if (pagination.sort.includes(sort)) {
            if (pagination.sort.includes('-')) {
                setPagination((state) => ({
                    ...state,
                    sort: sort
                }));
            } else {
                setPagination((state) => ({
                    ...state,
                    sort: `-${sort}`
                }));
            }
        } else {
            setPagination((state) => ({
                ...state,
                sort: sort
            }));
        }
    };

    return (
        <div className="flex flex-col gap-8 w-full">
            <h2 className="font-bold text-2xl">Dashboard</h2>
            <StatisticCards data={data} />
            <h2 className="font-bold text-2xl">Users</h2>
            {usersListData && (
                <div className="flex flex-col items-end gap-4">
                    <Input
                        placeholder="Filter usernames..."
                        className="max-w-sm self-start"
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <UserTable
                        usersListData={usersListData}
                        pagination={pagination}
                        handleChangeSort={handleChangeSort}
                    />
                    <PaginationControls
                        pagination={pagination}
                        setPagination={setPagination}
                        usersListData={usersListData}
                        handlePagePrev={handlePagePrev}
                        handlePageNext={handlePageNext}
                    />
                </div>
            )}
        </div>
    );
};

export default Home;
