// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_wrapper_desktop__hZHku {
  display: none;
}

.sidebar_wrapper_mobile__qNzGb {
  display: flex;
}
.sidebar_wrapper_mobile__qNzGb .sidebar_content__0N0Rq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}
.sidebar_wrapper_mobile__qNzGb .sidebar_left__YpW3F {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sidebar_wrapper_mobile__qNzGb .sidebar_right__Tg5GK {
  padding: 16px;
}

.sidebar_menu_opened__zyWvS {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: hsl(var(--background));
  padding: 80px 16px;
  z-index: 8;
}

@media (min-width: 768px) {
  .sidebar_wrapper_desktop__hZHku {
    display: flex;
  }
  .sidebar_wrapper_mobile__qNzGb {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/ui/sections/sidebar.module.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;AAFJ;;AAIA;EACI,aAAA;AADJ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,UAAA;AAAR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AAAR;AAGI;EACI,aAAA;AADR;;AAMA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,UAAA;AAHJ;;AAMA;EACI;IACI,aAAA;EAHN;EAKE;IACI,aAAA;EAHN;AACF","sourcesContent":["\n                    @import \"src/styles/_mixins.scss\";\n                \n.wrapper_desktop {\n    display: none;\n}\n.wrapper_mobile {\n    display: flex;\n    .content{\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        z-index: 9;\n    }\n    .left{\n        display: flex;\n        align-items: center;\n        gap: 8px;\n\n    }\n    .right{\n        padding: 16px;\n       \n    }\n}\n\n.menu_opened{\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100vh;\n    background: hsl(var(--background));\n    padding: 80px 16px;\n    z-index: 8;\n}\n\n@media (min-width: 768px) {\n    .wrapper_desktop {\n        display: flex;\n    }\n    .wrapper_mobile {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper_desktop": `sidebar_wrapper_desktop__hZHku`,
	"wrapper_mobile": `sidebar_wrapper_mobile__qNzGb`,
	"content": `sidebar_content__0N0Rq`,
	"left": `sidebar_left__YpW3F`,
	"right": `sidebar_right__Tg5GK`,
	"menu_opened": `sidebar_menu_opened__zyWvS`
};
export default ___CSS_LOADER_EXPORT___;
