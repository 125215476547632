import axios, { AxiosResponse } from 'axios';
import { IRewardsResponse } from '../typescript/responses';
import { IUpdateRewardsRequest } from '../typescript/requests';

export const apiGetRewards = (): Promise<AxiosResponse<IRewardsResponse>> => {
    return axios.get('/settings/daily-bonus-settings')
}

export const apiUpdateRewards = (data: IUpdateRewardsRequest) => {
    return axios.put('/settings/daily-bonus-settings', data)
}
