import React, { FC, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { apiGetUserById, apiGetReferralsInfo } from '@api/dashboard';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '@components/pagination';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@components/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@components/table';
import { Button } from '@components/button';
import { TUser } from '@types';
import { Avatar, AvatarFallback, AvatarImage } from '@components/avatar';
import { Card, CardDescription, CardTitle } from '@components/card';
import { EditIcon } from 'lucide-react';

interface IProps {
    user: TUser;
}

type TFullUser = {
    telegramId: number;
    languageCode: string;
    currentEnergy: number;
    lastEnergySet: string;
    lastActionDate: string;
};

type Referral = {
    id: string;
    level: number;
    username: string;
    balance: { current: number; total: number };
    isPremium: boolean;
};

type TReferralUser = Referral[];

type TReferralsList = {
    metadata: {
        totalPages: number;
    };
    data: {
        referrals: TReferralUser;
    };
};

const UserModal: FC<IProps> = ({ user }) => {
    const [pagination, setPagination] = useState<{ page: string; pageSize: string }>({
        page: '0',
        pageSize: '10'
    });
    const [fullUser, setFullUser] = useState<TFullUser | null>(null);
    const [referrals, setReferrals] = useState<TReferralsList>({
        metadata: { totalPages: 0 },
        data: { referrals: [] }
    });

    async function handleUserLoad() {
        const { data } = await apiGetUserById(user?.id);

        if (!data) return;

        setFullUser(data);
    }

    const [copySuccess, setCopySuccess] = useState('');

    const handleFieldClick = (value: string) => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setCopySuccess('Copied!');
                setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
                setCopySuccess('Failed to copy');
                setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
            });
    };

    const handlePagePrev = () => {
        if (referrals) {
            setPagination((state) => ({
                ...state,
                page: Number(state.page) > 0 ? String(Number(state.page) - 1) : state.page
            }));
        }
    };

    const handlePageNext = () => {
        if (referrals) {
            if ('metadata' in referrals) {
            } else {
                console.log('metadata not found');
            }
            const { totalPages } = referrals?.metadata;
            setPagination((state) => ({
                ...state,
                page:
                    Number(state.page) === totalPages - 1
                        ? state.page
                        : String(Number(state.page) + 1)
            }));
        }
    };

    let formattedDateAction = '';
    let formattedDateEnergy = '';

    if (fullUser) {
        formattedDateAction = format(parseISO(fullUser?.lastActionDate as string), 'yyyy-MM-dd');
        formattedDateEnergy = format(parseISO(fullUser?.lastEnergySet as string), 'yyyy-MM-dd');
    }

    const onReferralsFetch = async (telegramId: number) => {
        const { data: referralsList } = await apiGetReferralsInfo(telegramId, pagination);

        setReferrals(referralsList);
    };

    useEffect(() => {
        if (fullUser?.telegramId) onReferralsFetch(fullUser?.telegramId);
    }, [fullUser?.telegramId, pagination]);

    return (
        <Dialog>
            <DialogTrigger onClick={handleUserLoad} asChild>
                <Button variant="ghost">
                    <EditIcon />
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[960px]">
                <DialogHeader>
                    <DialogTitle>
                        User{' '}
                        <span className="pointer" onClick={() => handleFieldClick(user.username)}>
                            {user.username}
                        </span>
                    </DialogTitle>
                </DialogHeader>
                <div className="flex flex-row gap-2">
                    <div className="user-info flex flex-1 flex-col gap-8">
                        <div className="flex gap-4 items-center">
                            <Avatar>
                                <AvatarImage src={user.photo_url || ''} />
                                <AvatarFallback>{user.username.slice(0, 1)}</AvatarFallback>
                            </Avatar>
                            <span
                                className="pointer"
                                onClick={() => handleFieldClick(user.username)}
                            >
                                {user.username}
                            </span>
                            <div className="flex flex-col gap-1">
                                <p>
                                    {' '}
                                    id - ({' '}
                                    <span
                                        className="pointer"
                                        onClick={() => handleFieldClick(user.id)}
                                    >
                                        {user.id.slice(-6, user.id.length)}
                                    </span>{' '}
                                    )
                                </p>

                                <p>
                                    refId -
                                    <span
                                        className="pointer"
                                        onClick={() => handleFieldClick(user.refId)}
                                    >
                                        ( {user.refId} )
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-1 gap-2 flex-wrap flex-col">
                                <Card className=" p-3 flex flex-col gap-1 w-full">
                                    <CardDescription>Balance</CardDescription>
                                    <CardTitle>{user?.balance?.[0]?.current}</CardTitle>
                                </Card>
                                <Card className=" p-3 flex flex-col gap-1 w-full">
                                    <CardDescription>Total Balance</CardDescription>
                                    <CardTitle>{user?.balance?.[0]?.total}</CardTitle>
                                </Card>
                            </div>
                            <div className="flex flex-1 gap-2 flex-wrap">
                                <Card className="w-max flex-1 p-3 flex flex-col gap-1">
                                    <CardDescription>Level</CardDescription>
                                    <CardTitle>{user.level}</CardTitle>
                                </Card>
                                <Card className="w-max flex-1 p-3 flex flex-col gap-1">
                                    <CardDescription>Referrals</CardDescription>
                                    <CardTitle>{user.referralsCount}</CardTitle>
                                </Card>
                                <Card className="w-max flex-1 p-3 flex flex-col gap-1">
                                    <CardDescription>Is Premium</CardDescription>
                                    <CardTitle
                                        className={
                                            user.isPremium ? 'text-green-600' : 'text-red-600'
                                        }
                                    >
                                        {user.isPremium ? 'Yes' : 'No'}
                                    </CardTitle>
                                </Card>
                                <Card className="w-max flex-1 p-3 flex flex-col gap-1">
                                    <CardDescription>Language Code</CardDescription>
                                    <CardTitle>{fullUser?.languageCode}</CardTitle>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className="referrals flex flex-1 gap-8 flex-col ">
                        <div className="flex gap-2 flex-wrap">
                            Referrals
                            <Table className="w-full overflow-y-auto h-96">
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Username</TableHead>
                                        <TableHead className="w-[100px]">Balance</TableHead>
                                        <TableHead>Is Premium</TableHead>
                                        <TableHead>Level</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody className="max-h-10 overflow-scroll">
                                    {referrals?.data.referrals.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell className="text-center">
                                                {user.username}
                                            </TableCell>

                                            <TableCell className="text-center">
                                                {user?.balance?.current}
                                            </TableCell>
                                            <TableCell className="text-center">
                                                {user?.isPremium ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell className="text-center">
                                                {user.level}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Pagination className="w-max m-0 ">
                                <PaginationContent>
                                    <PaginationItem>
                                        <PaginationPrevious
                                            className="cursor-pointer"
                                            onClick={handlePagePrev}
                                        />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setPagination((state) => ({
                                                    ...state,
                                                    page: '0'
                                                }))
                                            }
                                        >
                                            First
                                        </PaginationLink>
                                    </PaginationItem>
                                    {Array(referrals?.metadata?.totalPages)
                                        .fill('')
                                        .map((_, i) => {
                                            if (
                                                i - 3 < Number(pagination.page) &&
                                                i + 3 > Number(pagination.page)
                                            ) {
                                                return (
                                                    <PaginationItem key={i}>
                                                        <PaginationLink
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                setPagination((state) => ({
                                                                    ...state,
                                                                    page: i.toString()
                                                                }))
                                                            }
                                                            isActive={
                                                                pagination.page === i.toString()
                                                            }
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            }
                                            return null;
                                        })}
                                    <PaginationItem>
                                        <PaginationLink
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setPagination((state) => ({
                                                    ...state,
                                                    page: String(
                                                        referrals?.metadata?.totalPages - 1
                                                    )
                                                }))
                                            }
                                        >
                                            Last
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationNext
                                            className="cursor-pointer"
                                            onClick={handlePageNext}
                                        />
                                    </PaginationItem>
                                </PaginationContent>
                            </Pagination>
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 mt-8">
                    <div
                        className="flex flex-1 column flex-column gap-2 flex-wrap"
                        style={{ flexDirection: 'column' }}
                    >
                        <h3> Last User Actions</h3>
                        <div className="flex gap-2 flew-wrap">
                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Last Action date</CardDescription>
                                <CardTitle> {formattedDateAction}</CardTitle>
                            </Card>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 gap-2 flex-wrap">
                        <h3>User Boosts And Energy</h3>
                        <div className="flex gap-2">
                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Current Energy</CardDescription>
                                <CardTitle>{fullUser?.currentEnergy}</CardTitle>
                            </Card>
                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Last Energy Set</CardDescription>

                                <CardTitle>{formattedDateEnergy}</CardTitle>
                            </Card>
                        </div>
                    </div>
                </div>
                <span style={{ marginLeft: 8, marginTop: 8 }}></span>
                {copySuccess && (
                    <>
                        <span
                            style={{
                                color: 'green',
                                position: 'absolute',
                                bottom: '8px',
                                paddingLeft: '1.5rem'
                            }}
                        >
                            {copySuccess}
                        </span>
                    </>
                )}
                {/*<DialogFooter>*/}
                {/*    <Button type="submit">Save changes</Button>*/}
                {/*</DialogFooter>*/}
            </DialogContent>
        </Dialog>
    );
};

export default UserModal;
