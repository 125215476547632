import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { EditIcon, TrashIcon, SquarePlus } from 'lucide-react';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import { Input } from '@components/input';
import { ECardType, ETaskType, ECreateTabsType } from '../../../typescript/enums';
import NestedTasks from '../nested_tasks';
import Upload from '../upload';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { IUpdateCard } from '../../../typescript/requests';
import { apiUpdateCard } from '@api/cards';
import { toast } from '@components/use-toast';
import { Switch } from '@components/switch';
import { TCard } from '@types';

import { Tabs, TabsList, TabsTrigger, TabsContent } from '@components/tabs';

export const defaultTask = {
    type: ETaskType.LEVEL,
    value: 0,
    cardId: ''
};

const newLevelFirst = {
    perHour: 3000,
    timeout: 10,
    price: 3000,
    tasks: [defaultTask]
};

interface IProps {
    data?: TCard;
    allCards: TCard[];
    onSuccess: () => void;
}

const timeoutsList = [10, 15, 30, 60, 300, 600, 1800];

const UpdateCardModal: FC<IProps> = ({ data, allCards, onSuccess }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const form = useForm<IUpdateCard>({
        mode: 'onChange',
        defaultValues: {
            category: ECardType.MARKET,
            title: {
                uk: '',
                ru: '',
                en: ''
            },
            hidden: false,
            description: {
                uk: '',
                ru: '',
                en: ''
            },
            subtitle: {
                uk: '',
                ru: '',
                en: ''
            },
            image: '',
            multipliers: {
                cost: 1.55,
                income: 1.32
            },
            levels: []
        }
    });

    const {
        fields: levelFields,
        remove,
        append
    } = useFieldArray({
        control: form.control,
        name: 'levels'
    });

    const { handleRequest } = useApiMutation({
        method: apiUpdateCard,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Card has been successfully updated`
            });
            setIsOpen(false);
            form.reset();
            onSuccess();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    const handleCreateCard = () => {
        const { title, description, subtitle, ...values } = form.getValues();
        handleRequest({
            ...data,
            ...values,
            title: title?.en && title?.ru && title?.uk ? title : undefined,
            description:
                description?.en && description?.ru && description?.uk ? description : undefined,
            subtitle: subtitle?.en && subtitle?.ru && subtitle?.uk ? subtitle : undefined,
            levels: values.levels.map((item) => {
                return {
                    ...item,
                    tasks: item.tasks.map((task) => {
                        return {
                            ...task,
                            cardId: task.cardId || undefined
                        };
                    })
                };
            })
        });
    };

    useEffect(() => {
        if (data) form.reset(data);
    }, [data]);

    const [activeTab, setActiveTab] = useState<ECreateTabsType>(ECreateTabsType.TRANSLATIONS);

    const updateTabs = [
        {
            label: 'Translations',
            value: ECreateTabsType.TRANSLATIONS
        },
        {
            label: 'Settings',
            value: ECreateTabsType.SETTINGS
        },
        {
            label: 'Levels',
            value: ECreateTabsType.LEVELS
        }
    ];

    const [newLevel, setNewLevel] = useState<any>();

    const handleNewLevel = useCallback(
        (idx: number) => {
            const {
                multipliers: { cost, income }
            } = form.getValues();

            console.log({
                newLevel,
                idx,
                data: data?.levels.length,
                data1: data?.levels,
                income,
                cost
            });

            if (!newLevel) {
                const lastLevel = data?.levels[data?.levels.length - 1] as any;

                console.log(lastLevel);

                const newIncome = Math.floor(lastLevel.perHour * Number(income));
                const newPrice = Math.floor(lastLevel.price * Number(cost));

                const newTimeout = timeoutsList[idx] || 7200;

                console.log({ newIncome, newPrice });

                setNewLevel({
                    perHour: newIncome,
                    timeout: newTimeout,
                    price: newPrice,
                    tasks: [defaultTask]
                });

                return;
            }

            const newIncome = Math.floor(newLevel.perHour * income);
            const newPrice = Math.floor(newLevel.price * cost);

            const newTimeout = timeoutsList[idx] || 7200;

            setNewLevel({
                perHour: newIncome,
                timeout: newTimeout,
                price: newPrice,
                tasks: [defaultTask]
            });

            console.log({ newLevel });
        },
        [newLevel]
    );

    const formValues = form.getValues();

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 edit w-full`} variant="ghost">
                    <EditIcon />
                </Button>
            </DialogTrigger>
            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Update card</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <div className="flex flex-col gap-3">
                            <Tabs value={activeTab} className="flex flex-col gap-6">
                                <div className="flex flex-col items-center gap-4">
                                    <TabsList className="rounded">
                                        {updateTabs.map((item) => (
                                            <TabsTrigger
                                                value={item.value}
                                                className="rounded"
                                                onClick={() => setActiveTab(item.value)}
                                            >
                                                {item.label}
                                            </TabsTrigger>
                                        ))}
                                    </TabsList>
                                    <TabsContent
                                        className="tabs-content"
                                        value={ECreateTabsType.TRANSLATIONS}
                                    >
                                        <div className="flex gap-2">
                                            <div className="w-full">
                                                <FormField
                                                    name="title.uk"
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Name UA</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Name"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="description.uk"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Description UA</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Description"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="subtitle.uk"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Subtitle UA</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Subtitle"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <FormField
                                                    name="title.ru"
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Name RU</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Name"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="description.ru"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Description RU</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Description"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="subtitle.ru"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Subtitle RU</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Subtitle"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <FormField
                                                    name="title.en"
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Name EN</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Name"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="description.en"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Description EN</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Description"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name="subtitle.en"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Subtitle EN</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Subtitle"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </TabsContent>
                                    <TabsContent
                                        className="tabs-content"
                                        value={ECreateTabsType.SETTINGS}
                                    >
                                        <div className="flex gap-2">
                                            {data?.category === ECardType.SPECIAL && (
                                                <FormField
                                                    name="expiredAt"
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Expired at</FormLabel>
                                                            <FormControl>
                                                                <div className="relative">
                                                                    <Input type="date" {...field} />
                                                                    <span className="newDataPickerIcon">
                                                                        📅
                                                                    </span>
                                                                </div>
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="flex items-center gap-5">
                                            <FormField
                                                name="image"
                                                render={({ field, fieldState: { error } }) => (
                                                    <FormItem className="w-full">
                                                        <FormLabel>Image</FormLabel>
                                                        <FormControl>
                                                            <Upload
                                                                value={field.value}
                                                                onChange={(data) => {
                                                                    form.setValue('newImage', data);
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {error?.message && (
                                                            <FormDescription>
                                                                Invalid field
                                                            </FormDescription>
                                                        )}
                                                    </FormItem>
                                                )}
                                            />
                                            {data?.category === ECardType.SPECIAL && (
                                                <FormField
                                                    name="bigImage"
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Background Image</FormLabel>
                                                            <FormControl>
                                                                <Upload
                                                                    value={field.value}
                                                                    onChange={(data) => {
                                                                        form.setValue(
                                                                            'newBigImage',
                                                                            data
                                                                        );
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                        </FormItem>
                                                    )}
                                                />
                                            )}
                                            <FormField
                                                name="hidden"
                                                render={({ field }) => (
                                                    <FormItem className="w-full flex items-center gap-2">
                                                        <FormLabel>Is hidden</FormLabel>
                                                        <FormControl>
                                                            <Switch
                                                                checked={field.value}
                                                                onCheckedChange={field.onChange}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </TabsContent>
                                    <TabsContent
                                        className="tabs-content"
                                        value={ECreateTabsType.LEVELS}
                                    >
                                        <div className="flex flex-col gap-2 mb-6">
                                            <h2>Multipliers</h2>
                                            <div className="flex gap-4 items-end pl-4">
                                                <FormItem className="w-full">
                                                    <FormLabel>Cost</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name="multipliers.cost"
                                                        render={({ field }) => (
                                                            <Input
                                                                placeholder="Cost Multiplier"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </FormItem>

                                                <FormItem className="w-full">
                                                    <FormLabel>Income</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name="multipliers.income"
                                                        render={({ field }) => (
                                                            <Input
                                                                placeholder="Income Multiplier"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </FormItem>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            Levels
                                            {levelFields.map((item, i) => {
                                                return (
                                                    <div
                                                        className={`flex flex-col gap-2 pt-3 pb-3 ${i % 2 === 0 ? 'bg-destructive2' : ''}`}
                                                    >
                                                        <div
                                                            key={item.id}
                                                            className="flex flex-col md:flex-row gap-4 items-end pl-4 pr-4 md:pr-0"
                                                        >
                                                            <FormField
                                                                name={`levels.${i}.level`}
                                                                render={({
                                                                    field,
                                                                    fieldState: { error }
                                                                }) => (
                                                                    <FormItem className="w-full">
                                                                        <FormLabel>Level</FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                placeholder="Level"
                                                                                {...field}
                                                                                disabled
                                                                            />
                                                                        </FormControl>
                                                                        {error?.message && (
                                                                            <FormDescription>
                                                                                Invalid field
                                                                            </FormDescription>
                                                                        )}
                                                                    </FormItem>
                                                                )}
                                                                rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Required field'
                                                                    }
                                                                }}
                                                            />
                                                            <FormField
                                                                name={`levels.${i}.price`}
                                                                render={({
                                                                    field,
                                                                    fieldState: { error }
                                                                }) => (
                                                                    <FormItem className="w-full">
                                                                        <FormLabel>Price</FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                placeholder="Price"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        {error?.message && (
                                                                            <FormDescription>
                                                                                Invalid field
                                                                            </FormDescription>
                                                                        )}
                                                                    </FormItem>
                                                                )}
                                                                rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Required field'
                                                                    }
                                                                }}
                                                            />
                                                            <Controller
                                                                name={`levels.${i}.perHour`}
                                                                control={form.control}
                                                                rules={{
                                                                    required: 'Profit is required',
                                                                    validate: {
                                                                        isBigger: (value) =>
                                                                            i === 0
                                                                                ? true
                                                                                : Number(value) >
                                                                                      Number(
                                                                                          formValues
                                                                                              .levels[
                                                                                              i - 1
                                                                                          ]?.perHour
                                                                                      ) ||
                                                                                  'Profit must be bigger than it prev level'
                                                                    }
                                                                }}
                                                                render={({
                                                                    field,
                                                                    fieldState: { error }
                                                                }) => (
                                                                    <FormItem className="w-full">
                                                                        <FormLabel>
                                                                            Per hour
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <div className="flex items-center gap-1">
                                                                                <p className="absolute ml-2">
                                                                                    +
                                                                                </p>
                                                                                <Input
                                                                                    {...field}
                                                                                    className="pl-5"
                                                                                />
                                                                            </div>
                                                                        </FormControl>
                                                                        {error?.message && (
                                                                            <FormMessage>
                                                                                {error?.message}
                                                                            </FormMessage>
                                                                        )}
                                                                    </FormItem>
                                                                )}
                                                            />

                                                            <Controller
                                                                name={`levels.${i}.timeout`}
                                                                control={form.control}
                                                                rules={{
                                                                    required: 'Timeout is required',
                                                                    validate: {
                                                                        isBigger: (value) =>
                                                                            i === 0
                                                                                ? true
                                                                                : Number(value) >
                                                                                      Number(
                                                                                          formValues
                                                                                              .levels[
                                                                                              i - 1
                                                                                          ]?.timeout
                                                                                      ) ||
                                                                                  'Timeout must be bigger than it prev level'
                                                                    }
                                                                }}
                                                                render={({
                                                                    field,
                                                                    fieldState: { error }
                                                                }) => (
                                                                    <FormItem className="w-full">
                                                                        <FormLabel>
                                                                            Timeout (seconds)
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                type="number"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        {error?.message && (
                                                                            <FormMessage>
                                                                                {error?.message}
                                                                            </FormMessage>
                                                                        )}
                                                                    </FormItem>
                                                                )}
                                                            />
                                                            <Button
                                                                variant="ghost"
                                                                onClick={() => remove(i)}
                                                            >
                                                                <TrashIcon color="red" />
                                                            </Button>
                                                            <Button
                                                                variant="ghost"
                                                                onClick={() =>
                                                                    append({
                                                                        ...newLevelFirst,
                                                                        level:
                                                                            levelFields.length + 1
                                                                    })
                                                                }
                                                            >
                                                                <SquarePlus />
                                                            </Button>
                                                        </div>
                                                        <NestedTasks
                                                            control={form.control}
                                                            nestIndex={i}
                                                            allCards={allCards}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            <Button
                                                className="mt-2 ml-4"
                                                onClick={() => {
                                                    console.log({ newLevel }, levelFields.length);

                                                    append({
                                                        ...newLevel,
                                                        level: levelFields.length + 1
                                                    });

                                                    handleNewLevel(levelFields.length);
                                                }}
                                            >
                                                Add level
                                            </Button>
                                        </div>
                                    </TabsContent>
                                </div>
                            </Tabs>
                        </div>
                    </Form>
                    <DialogFooter style={{ marginTop: 'auto' }}>
                        <Button onClick={handleCreateCard}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default UpdateCardModal;
