import { useEffect } from 'react';

const ThemeSwitcher = () => {
    useEffect(() => {
        const sunIcon = document.querySelector('.sun');
        const moonIcon = document.querySelector('.moon');

        type TTheme = 'dark' | 'light';
        function setTheme(theme: TTheme) {
            if (theme === 'dark') {
                document.documentElement.classList.remove('light');
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
                document.documentElement.classList.add('light');
            }
        }

        if (
            localStorage.theme === 'dark' ||
            (!('theme' in localStorage) &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
            setTheme('dark');
        } else {
            setTheme('light');
        }

        sunIcon?.addEventListener('click', () => {
            localStorage.theme = 'light';
            setTheme('light');
        });

        moonIcon?.addEventListener('click', () => {
            localStorage.theme = 'dark';
            setTheme('dark');
        });

        return () => {
            sunIcon?.removeEventListener('click', () => {});
            moonIcon?.removeEventListener('click', () => {});
        };
    }, []);

    return null;
};

export default ThemeSwitcher;
