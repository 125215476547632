import axios, { AxiosResponse } from 'axios';
import { TDailyChest, TTask } from '@types';

export const apiGetChests = (): Promise<AxiosResponse<TDailyChest[]>> => {
    return axios.get('/settings/chests')
}

export const apiUpdateChest = ({ id, ...data }: Omit<TDailyChest, 'task'> & {task?: TTask}) => {
    return axios.put(`/settings/chests/${id}`, data);
};

export const apiCreateChest = (data: Omit<TDailyChest, 'id'> & {task?: TTask}) => {
    return axios.post(`/settings/chests`, data);
};
