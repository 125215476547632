import React from 'react';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@components/card';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiGetChests } from '@api/chests';
import { EDailyChestType } from '../../typescript/enums';
import UpdateChestModal from '../components/modals/update_chest';
import { apiGetAllCards } from '@api/cards';

const Chests = () => {
    const { data: chests, handleRequest: refetchChests } = useApiQuery({
        method: apiGetChests,
        isInitialRequest: true
    });

    const { data: allCards } = useApiQuery({
        method: apiGetAllCards,
        isInitialRequest: true
    });

    const getChestType = (type: EDailyChestType) => {
        if (type === EDailyChestType.DAILY) {
            return 'Daily chest (first)';
        } else if (type === EDailyChestType.DAILY_TASK) {
            return 'Daily task chest (second)';
        } else {
            return 'Task chest (third)';
        }
    };

    return (
        <div className="flex flex-col gap-6">
            {chests && (
                <div className="flex gap-4 flex-wrap">
                    {chests.map((item) => (
                        <Card
                            key={item.id}
                            className="w-[calc(100%-4px)] md:w-[calc(33%-20px)] flex flex-col justify-between relative pt-4"
                        >
                            <span className="absolute top-2 right-2 border border-[#27272a] p-1 rounded">
                                {getChestType(item.type)}
                            </span>
                            <CardHeader className="flex flex-col gap-4">
                                <CardTitle>{item?.title?.uk}</CardTitle>
                                <CardDescription>{item?.description?.uk}</CardDescription>
                            </CardHeader>
                            <CardFooter className="flex gap-2">
                                <UpdateChestModal
                                    allCards={allCards || []}
                                    data={item}
                                    onSuccess={refetchChests}
                                />
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Chests;
