import axios, { AxiosResponse } from 'axios';
import { TDailyTask } from '@types';
import { ICreateDailyTask, IUpdateDailyTask } from '../typescript/requests';

export const apiGetTasks = (): Promise<AxiosResponse<TDailyTask[]>> => {
    return axios.get('/settings/daily-tasks')
}

export const apiCreateTask = (data: ICreateDailyTask): Promise<AxiosResponse<TDailyTask>> => {
    return axios.post('/settings/daily-tasks', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export const apiUpdateTask = ({ id, ...data }: IUpdateDailyTask): Promise<AxiosResponse<TDailyTask>> => {
    return axios.patch(`/settings/daily-tasks/${id}`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

export const apiDeleteTask = (id: string): Promise<AxiosResponse<void>> => {
    return axios.delete(`/settings/daily-tasks/${id}`);
};
