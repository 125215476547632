import React, { ChangeEvent, useState } from 'react';

interface IProps {
    value: string | FormData;
    onChange: (value: {file: FormData, mimetype: string, filename: string}) => void;
}

const Upload = ({ value, onChange }: IProps) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();

    const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e?.target?.files;

        if (files?.length === 1) {
            const selectedFile = files[0]
            const reader = new FileReader();
            reader.onloadend = async () => {
                //@ts-ignore
                const blob = new Blob([reader.result], { type: selectedFile.type });

                const formData = new FormData();
                formData.append('image', blob, selectedFile.name);
                onChange({
                    //@ts-ignore
                    file: blob,
                    mimetype: selectedFile.type,
                    filename: selectedFile.name,
                })
                console.log({
                    //@ts-ignore
                    file: blob,
                    mimetype: selectedFile.type,
                    filename: selectedFile.name,
                });
            };
            reader.readAsArrayBuffer(selectedFile);
            setImagePreviewUrl(URL.createObjectURL(files[0]))
        } else {
            console.log('No file selected');
        }
    };

    return (
        <label className="cursor-pointer">
            <input
                className="hidden opacity-0 w-0 h-0"
                type="file"
                accept="image/*"
                onChange={handleUploadImage}
            />
            {(!(value && typeof value === 'string') && !imagePreviewUrl) && <div>Upload Image</div>}
            {((value && typeof value === 'string') || imagePreviewUrl) && (
                <img className="w-28 h-max" src={!imagePreviewUrl ? `${process.env.REACT_APP_IMAGE_URL}${value}` : imagePreviewUrl} alt="Image Preview" />
            )}
        </label>
    );
};

export default Upload;
