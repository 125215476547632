import axios, { AxiosResponse } from 'axios';
import { IGetUsersListRequest } from '../typescript/requests';
import { IGetUsersListResponse, IGetUsersStatsResponse } from '../typescript/responses';

export const apiGetUsersStats = (): Promise<AxiosResponse<IGetUsersStatsResponse>> => {
    return axios.get("/dashboard/get-users-stats");
}

export const apiGetUsersList = ({
    page,
    pageSize,
    username,
    sort,
}: IGetUsersListRequest): Promise<AxiosResponse<IGetUsersListResponse>> => {
    return axios.post(`/dashboard/users?page=${page}&pageSize=${pageSize}&sort={"${sort.replace("-", "")}":${sort.includes("-") ? -1 : 1}}`, {username: username || undefined});
};

export const apiGetUserById = (id: number | string) => {
    return axios.get(`/dashboard/user/${id}`)
}

export const apiGetReferralsInfo = (id: number | string, pagination: { page: string, pageSize: string}) => {
    return axios.get(`/dashboard/user/${id}/referrals?page=${pagination.page}&pageSize=${pagination.pageSize}`)
}
