import { ECardType } from '../typescript/enums';

export const cardsTabs = [
    {
        label: "Team",
        value: ECardType.TEAM,
    },
    {
        label: "Tech",
        value: ECardType.MARKET,
    },
    {
        label: "Legal",
        value: ECardType.LEGAL,
    },
    {
        label: "Grand",
        value: ECardType.SPECIAL,
    },
]
