import React, { useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@components/tabs';
import { cardsTabs } from '../../constants/cards';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@components/card';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiDeleteCard, apiGetAllCards, apiGetCardsByCategory } from '@api/cards';
import { ECardType } from '../../typescript/enums';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { toast } from '@components/use-toast';
import CreateCardModal from '../components/modals/create_card';
import UpdateCardModal from '../components/modals/update_card';
import DeleteCardModal from './components/cards/delete_card';

const Cards = () => {
    const [activeTab, setActiveTab] = useState<ECardType>(ECardType.MARKET);

    const { data, handleRequest: handleGetCards } = useApiQuery({
        method: apiGetCardsByCategory,
        data: activeTab,
        deps: [activeTab],
        isInitialRequest: true
    });

    const { data: allCards, handleRequest: handleGetAllCards } = useApiQuery({
        method: apiGetAllCards,
        isInitialRequest: true
    });

    const { handleRequest: handleDeleteCard } = useApiMutation({
        method: apiDeleteCard,
        onSuccess: () => {
            handleGetCards();
            handleGetAllCards();
            toast({
                variant: 'default',
                description: `Card has been successfully deleted`
            });
        }
    });

    return (
        <div>
            <h2 className="font-bold text-2xl mb-4 md:mb-1">Cards</h2>
            <Tabs value={activeTab} className="flex flex-col gap-6">
                <div className="flex items-center gap-4 flex-col md:flex-row">
                    <TabsList className="rounded">
                        {cardsTabs.map((item) => (
                            <TabsTrigger
                                value={item.value}
                                className="rounded"
                                onClick={() => setActiveTab(item.value)}
                            >
                                {item.label}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                    <CreateCardModal
                        category={activeTab}
                        allCards={allCards || []}
                        onSuccess={() => {
                            handleGetCards();
                            handleGetAllCards();
                        }}
                    />
                </div>
                <div className="flex gap-4 flex-wrap">
                    {data &&
                        data.map((item) => {
                            return (
                                <Card
                                    key={item.id}
                                    className="w-[calc(50%-8px)] md:w-[calc(12%-20px)] flex flex-col justify-between"
                                >
                                    <CardHeader>
                                        <CardTitle>
                                            {item?.title?.en} ({item.hidden && 'hidden'}){' '}
                                        </CardTitle>
                                        <CardDescription>{item?.description?.uk}</CardDescription>
                                        <div
                                            className="w-[10px] h-[10px] mt-2 "
                                            style={{
                                                borderRadius: '50%',
                                                background: item.hidden ? 'red' : 'green'
                                            }}
                                        ></div>
                                    </CardHeader>
                                    <CardContent>
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}${item.image}`}
                                            alt={item?.title?.uk}
                                        />
                                    </CardContent>
                                    <CardFooter className="flex gap-2">
                                        <UpdateCardModal
                                            data={item}
                                            allCards={allCards || []}
                                            onSuccess={() => {
                                                handleGetCards();
                                                handleGetAllCards();
                                            }}
                                        />
                                        <DeleteCardModal
                                            onSuccess={() => handleDeleteCard(item.id)}
                                        />
                                    </CardFooter>
                                </Card>
                            );
                        })}
                </div>
            </Tabs>
        </div>
    );
};

export default Cards;
