import React from 'react';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiGetRewards, apiUpdateRewards } from '@api/rewards';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@components/form';
import { useFieldArray, useForm } from 'react-hook-form';
import { IRewardsResponse } from '../../typescript/responses';
import { Input } from '@components/input';
import { Button } from '@components/button';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { toast } from '@components/use-toast';

const Daily = () => {
    const formMethods = useForm<IRewardsResponse>({
        mode: 'onChange',
        defaultValues: {
            rewards: []
        }
    })
    const {fields} = useFieldArray({ name: 'rewards', control: formMethods.control })
    const {handleRequest: refetchRewards} = useApiQuery({
        method: apiGetRewards,
        isInitialRequest: true,
        onSuccess: (data) => {
            formMethods.reset(data)
        }
    });
    const {handleRequest: updateRewards} = useApiMutation({
        method: apiUpdateRewards,
        onSuccess: () => {
            toast({title: "Update successfully rewards", variant: "default"})
            refetchRewards()
        },
        onError: (e) => {
            toast({title: e?.message || "Update rewards filed", variant: "destructive"})
        }
    })

    const onSubmit = () => {
        updateRewards({
            ...formMethods.getValues(),
            rewards: formMethods.getValues().rewards.map(item => ({...item, day: Number(item.day), amount: Number(item.amount)}))
        })
    }

    return (
        <div className="flex flex-col gap-8">
            <h2 className="font-bold text-2xl">Daily bonuses</h2>
            <div className="flex gap-x-8 gap-y-4 flex-wrap">
                <Form {...formMethods}>
                    {fields.map((item, i) => {
                        return <div key={item.day} className="flex items-center gap-2">
                            <FormField
                                name={`rewards.${i}.day`}
                                render={({ field }) => <FormItem className="w-20">
                                    <FormLabel>Day</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Day"
                                            {...field}
                                            type="number"
                                            disabled
                                        />
                                    </FormControl>
                                </FormItem>}
                            />
                            <FormField
                                name={`rewards.${i}.amount`}
                                render={({ field }) => <FormItem className="w-64">
                                    <FormLabel>Amount</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Amount"
                                            {...field}
                                            type="number"
                                        />
                                    </FormControl>
                                </FormItem>}
                            />
                        </div>
                    })}
                </Form>
            </div>
            <Button className="w-max" onClick={onSubmit}>
                Update
            </Button>
        </div>
    );
};

export default Daily;
