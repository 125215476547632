import React, { FC, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { EditIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import { Input } from '@components/input';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { IUpdateDailyTask } from '../../../typescript/requests';
import { toast } from '@components/use-toast';
import { apiUpdateTask } from '@api/tasks';
import Upload from '../upload';
import { TDailyTask } from '@types';
import moment from 'moment';
import { EDailyType } from '../../../typescript/enums';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@components/select';

interface IProps {
    onSuccess: () => void;
    data: TDailyTask;
}

const UpdateTaskModal: FC<IProps> = ({ onSuccess, data }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const form = useForm<IUpdateDailyTask>({
        mode: 'onChange',
        defaultValues: {
            title: {
                uk: '',
                ru: '',
                en: ''
            },
            description: {
                uk: '',
                ru: '',
                en: ''
            },
            image: '',
            newImage: {
                file: null,
                filename: '',
                mimetype: ''
            },
            expiry: undefined,
            amount: '1000',
            link: '',
            chatId: '',
            type: EDailyType.TELEGRAM
        }
    });

    const { handleRequest } = useApiMutation({
        method: apiUpdateTask,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Task has been successfully updated`
            });
            setIsOpen(false);
            onSuccess();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    const onSubmit = () => {
        const { description, ...values } = form.getValues();
        handleRequest({
            ...values,
            description:
                description?.en && description?.ru && description?.uk ? description : undefined
        });
    };

    useEffect(() => {
        if (data) {
            //@ts-ignore
            form.reset({
                ...data,
                expiry: moment(data.expiry).format('YYYY-MM-DD')
            });
        }
    }, [data]);

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 edit w-full`} variant="ghost">
                    <EditIcon />
                </Button>
            </DialogTrigger>
            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Update task</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <div className="flex flex-col gap-3">
                            <FormField
                                name="image"
                                render={({ field, fieldState: { error } }) => (
                                    <FormItem className="w-full">
                                        <FormLabel>Image</FormLabel>
                                        <FormControl>
                                            <Upload
                                                value={field.value}
                                                onChange={(data) => {
                                                    //@ts-ignore
                                                    form.setValue('newImage', data);
                                                }}
                                            />
                                        </FormControl>
                                        {error?.message && (
                                            <FormDescription>Invalid field</FormDescription>
                                        )}
                                    </FormItem>
                                )}
                            />
                            <div className="flex gap-2">
                                <div className="w-full">
                                    <FormField
                                        name="title.uk"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name UA</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.uk"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description UA</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.ru"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name RU</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.ru"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description RU</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.en"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name EN</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.en"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description EN</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex gap-2">
                                <FormField
                                    name="link"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Link</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Link" {...field} />
                                            </FormControl>
                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Required field'
                                        }
                                    }}
                                />
                                <FormField
                                    name="type"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Link</FormLabel>
                                            <FormControl>
                                                <Select
                                                    value={field.value}
                                                    onValueChange={field.onChange}
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Type" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectItem value={EDailyType.TELEGRAM}>
                                                                Telegram
                                                            </SelectItem>

                                                            <SelectItem value={EDailyType.TWITTER}>
                                                                Twitter
                                                            </SelectItem>

                                                            <SelectItem value={EDailyType.TRUST}>
                                                                Trust wallet
                                                            </SelectItem>

                                                            <SelectItem value={EDailyType.EXTERNAL}>
                                                                External
                                                            </SelectItem>

                                                            <SelectItem
                                                                value={EDailyType.EXTERNAL_LINK}
                                                            >
                                                                EXTERNAL LINK
                                                            </SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Required field'
                                        }
                                    }}
                                />
                                <FormField
                                    name="chatId"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Chat ID</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Chat ID" {...field} />
                                            </FormControl>
                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-full flex gap-2">
                                <FormField
                                    name="amount"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Amount</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Amount"
                                                    {...field}
                                                    type="number"
                                                />
                                            </FormControl>
                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Required field'
                                        }
                                    }}
                                />
                                <FormField
                                    name="expiry"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Expired at </FormLabel>
                                            <FormControl>
                                                <div className="relative">
                                                    <Input
                                                        placeholder="Expired at "
                                                        {...field}
                                                        type="date"
                                                    />
                                                    <span className="newDataPickerIcon">📅</span>
                                                </div>
                                            </FormControl>
                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </Form>
                    <DialogFooter style={{ marginTop: 'auto' }}>
                        <Button onClick={onSubmit}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default UpdateTaskModal;
