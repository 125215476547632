import React from 'react';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '@components/pagination';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@components/select';

interface PaginationControlsProps {
    pagination: {
        page: string;
        pageSize: string;
    };
    setPagination: React.Dispatch<React.SetStateAction<{ page: string; pageSize: string; sort: string }>>;
    usersListData: {
        metadata: {
            totalPages: number;
        };
    };
    handlePagePrev: () => void;
    handlePageNext: () => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
    pagination,
    setPagination,
    usersListData,
    handlePagePrev,
    handlePageNext
}) => {
    return (
        <Pagination className="w-max m-0">
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious className="cursor-pointer" onClick={handlePagePrev} />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink className="cursor-pointer" onClick={() => setPagination(state => ({ ...state, page: '0' }))}>
                        First
                    </PaginationLink>
                </PaginationItem>
                {Array(usersListData.metadata.totalPages)
                    .fill('')
                    .map((_, i) => {
                        if (i - 3 < Number(pagination.page) && i + 3 > Number(pagination.page)) {
                            return (
                                <PaginationItem key={i}>
                                    <PaginationLink
                                        className="cursor-pointer"
                                        onClick={() => setPagination(state => ({ ...state, page: i.toString() }))}
                                        isActive={pagination.page === i.toString()}
                                    >
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        }
                        return null;
                    })}
                <PaginationItem>
                    <PaginationLink
                        className="cursor-pointer"
                        onClick={() => setPagination(state => ({ ...state, page: String(usersListData.metadata.totalPages - 1) }))}
                    >
                        Last
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationNext className="cursor-pointer" onClick={handlePageNext} />
                </PaginationItem>
            </PaginationContent>
            <Select
                value={pagination.pageSize}
                onValueChange={(val) => setPagination((state) => ({ ...state, pageSize: val }))}
            >
                <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Page size" defaultValue={50} />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value="100">100</SelectItem>
                        <SelectItem value="200">200</SelectItem>
                        <SelectItem value="500">500</SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </Pagination>
    );
};

export default PaginationControls;
