import React, { useState } from 'react';
import { routes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { Button } from '@components/button';
import { LogOutIcon, Sun, Moon, Menu, X } from 'lucide-react';
import { useUserContext } from '@hooks/context/user_context';

import styles from './sidebar.module.scss';

const Sidebar = () => {
    const { handleLogout } = useUserContext();
    return (
        <div
            className={`${styles.wrapper_desktop} flex flex-col w-64 gap-6 pt-4 justify-between h-[100vh] border-r sticky top-0`}
        >
            <div>
                <div className="p-4 text-2xl font-bold">Grand Combat</div>
                <div>
                    <div className=" w-[100%] flex inline-flex items-center justify-center p-2 rounded-md gap-6">
                        <Moon className="moon cursor-pointer" />
                        <Sun className="sun cursor-pointer" />
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    {routes.map((route) => (
                        <Link
                            to={route.link}
                            key={route.link}
                            className="flex items-center gap-2 w-full p-4 hover:bg-[rgba(255,255,255,0.2)] transition"
                        >
                            <route.Icon />
                            {route.label}
                        </Link>
                    ))}
                </div>
            </div>
            <Button variant="destructive" className="gap-2" onClick={handleLogout}>
                <LogOutIcon /> Logout
            </Button>
        </div>
    );
};

export const SideBarMobile = () => {
    const [open, setOpen] = useState(false);
    const { handleLogout } = useUserContext();
    return (
        <div
            className={`${styles.wrapper_mobile} flex flex-col w-64 gap-6 pt-4 justify-between border-r w-[100%]`}
        >
            <div className={styles.content}>
                <div className={styles.left}>
                    <div className="p-4 text-2xl font-bold">Grand Combat</div>
                    <div>
                        <div className=" w-[100%] flex inline-flex items-center justify-center p-2 rounded-md gap-6">
                            <Moon className="moon cursor-pointer" />
                            <Sun className="sun cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div className={styles.right} onClick={() => setOpen(!open)}>
                    {open ? <X /> : <Menu />}
                </div>
            </div>
            {open && (
                <div className={styles.menu_opened}>
                    {routes.map((route) => (
                        <Link
                            to={route.link}
                            key={route.link}
                            className="flex items-center gap-2 w-full p-4 hover:bg-[rgba(255,255,255,0.2)] transition"
                        >
                            <route.Icon />
                            {route.label}
                        </Link>
                    ))}
                    <Button variant="destructive" className="gap-2" onClick={handleLogout}>
                        <LogOutIcon /> Logout
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Sidebar;
