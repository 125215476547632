import React, { FC } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '@components/form';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from '@components/select';
import { Input } from '@components/input';
import { Button } from '@components/button';
import { defaultTask } from './modals/create_card';
import { TrashIcon } from 'lucide-react';
import { ECardType, ETaskType } from '../../typescript/enums';
import { TCard } from '@types';

interface IProps {
    nestIndex: number;
    control: any;
    allCards: TCard[];
}

const NestedTasks: FC<IProps> = ({ control, nestIndex, allCards }) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `levels.${nestIndex}.tasks`
    });
    const fieldsValuesArray = useWatch({ name: `levels.${nestIndex}.tasks`, control });
    const currentId = useWatch({ name: `id`, control });

    return (
        <div>
            {fields.map((item, i) => {
                return (
                    <div
                        className="flex flex-col md:flex-row pl-4 pr-4 items-end gap-2 md:pl-8 md:pr-0"
                        key={item.id}
                    >
                        <FormField
                            name={`levels.${nestIndex}.tasks.${i}.type`}
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Type</FormLabel>
                                    <FormControl>
                                        <Select value={field.value} onValueChange={field.onChange}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value={ETaskType.LEVEL}>
                                                        User level
                                                    </SelectItem>
                                                    <SelectItem value={ETaskType.CARD}>
                                                        Card level
                                                    </SelectItem>
                                                    <SelectItem value={ETaskType.REFERRALS}>
                                                        Referrals count
                                                    </SelectItem>
                                                    <SelectItem value={ETaskType.NEW_REFERRALS}>
                                                        New Referrals count
                                                    </SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                </FormItem>
                            )}
                            rules={{
                                required: { value: true, message: 'Required field' }
                            }}
                        />
                        <FormField
                            name={`levels.${nestIndex}.tasks.${i}.value`}
                            render={({ field, fieldState: { error } }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Value</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    {error?.message && (
                                        <FormDescription>Invalid field</FormDescription>
                                    )}
                                </FormItem>
                            )}
                            rules={{
                                required: { value: true, message: 'Required field' }
                            }}
                        />
                        {fieldsValuesArray[i]?.type === ETaskType.CARD && (
                            <FormField
                                name={`levels.${nestIndex}.tasks.${i}.cardId`}
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormLabel>Card</FormLabel>
                                        <FormControl>
                                            <Select
                                                value={field.value}
                                                onValueChange={field.onChange}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Card name" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectLabel>Market</SelectLabel>
                                                        {allCards
                                                            .filter(
                                                                (item) =>
                                                                    item.category ===
                                                                        ECardType.MARKET &&
                                                                    item.id !== currentId
                                                            )
                                                            .map((item) => (
                                                                <SelectItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.title?.uk} (
                                                                    {item.category})
                                                                </SelectItem>
                                                            ))}
                                                    </SelectGroup>
                                                    <SelectGroup>
                                                        <SelectLabel>Team</SelectLabel>
                                                        {allCards
                                                            .filter(
                                                                (item) =>
                                                                    item.category ===
                                                                        ECardType.TEAM &&
                                                                    item.id !== currentId
                                                            )
                                                            .map((item) => (
                                                                <SelectItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.title?.uk} (
                                                                    {item.category})
                                                                </SelectItem>
                                                            ))}
                                                    </SelectGroup>
                                                    <SelectGroup>
                                                        <SelectLabel>Legal</SelectLabel>
                                                        {allCards
                                                            .filter(
                                                                (item) =>
                                                                    item.category ===
                                                                        ECardType.LEGAL &&
                                                                    item.id !== currentId
                                                            )
                                                            .map((item) => (
                                                                <SelectItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.title?.uk} (
                                                                    {item.category})
                                                                </SelectItem>
                                                            ))}
                                                    </SelectGroup>
                                                    <SelectGroup>
                                                        <SelectLabel>Special</SelectLabel>
                                                        {allCards
                                                            .filter(
                                                                (item) =>
                                                                    item.category ===
                                                                        ECardType.SPECIAL &&
                                                                    item.id !== currentId
                                                            )
                                                            .map((item) => (
                                                                <SelectItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.title?.uk} (
                                                                    {item.category})
                                                                </SelectItem>
                                                            ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                    </FormItem>
                                )}
                                rules={{
                                    required: { value: true, message: 'Required field' }
                                }}
                            />
                        )}
                        <Button variant="ghost" onClick={() => remove(i)}>
                            <TrashIcon color="red" />
                        </Button>
                    </div>
                );
            })}
            <Button className="ml-8 mt-2" onClick={() => append(defaultTask)}>
                Add task
            </Button>
        </div>
    );
};

export default NestedTasks;
