import React, {useState} from 'react';
import {ECardType} from "../../typescript/enums";

//Icons
import {EditIcon, EyeIcon, EyeOffIcon} from 'lucide-react';

import {useApiQuery} from "@hooks/api/useApiQuery";
import {apiGetAllCards} from "@api/cards";
import ComboUpdateCard from "../components/modals/combo_update_card";
import ComboLoot from "../components/modals/combo_loot";
import {getCombos, updateComboLoot} from "@api/combo";
import {TComboCard} from "@types";
import {toast} from "@components/use-toast";

const Combo = () => {
    const [upd, setUpd] = useState(Math.random())

    const {data} = useApiQuery({
        method: getCombos,
        isInitialRequest: true,
        deps: [upd]
    });

    const onSubmitHide = async () => {

        if (typeof data === "undefined") return

        const res = await updateComboLoot(data._id, {hidden: !data?.hidden});

        if (res.status === 200) {
            toast({
                variant: "default",
                description: `Combo Successfully Changed`
            });

            setUpd(Math.random())

        } else {
            toast({
                variant: "destructive",
                description: `Something went wrong`
            });
        }
    };

    return (
        <div className="flex flex-col gap-8 w-full">
            <h2 className="font-bold text-2xl">Сombo</h2>
            <div className={"w-full flex items-center justify-center cursor-pointer"} onClick={() => onSubmitHide()}>

                {
                    data?.hidden?
                        <>
                            <span>Combo inactive</span>
                            <EyeOffIcon/>
                        </> :
                        <>
                            <span>Combo active</span>
                            <EyeIcon />
                        </>

                }
            </div>
            <div className={"flex items-center justify-center gap-6"}>
                {typeof data !== undefined && data?.cards?.map((el: any, k: number) => ( //Cards
                    <div
                        key={`combo-${k}`}
                        className={"box-border p-4 w-[230px] h-fit rounded-xl border bg-card text-card-foreground  flex flex-col gap-4 justify-between"}
                    >
                        <div
                            className={"w-full flex items-center justify-center font-semibold leading-none tracking-tight"}>{el.title.en}</div>
                        <img
                            className="h-fit max-h-fit rounded-xl object-cover"
                            src={`${process.env.REACT_APP_IMAGE_URL}${el.image}`}
                            alt="Image Preview"
                        />

                        <div className={"w-full flex items-center justify-center"}>
                            <ComboUpdateCard setUpd={() => setUpd(Math.random())} data={data}
                                             cardId={el._id}
                                             cardTitle={el.title.en}/>
                        </div>
                    </div>
                ))}
            </div>

            <ComboLoot data={data}/>
        </div>
    );
};

export default Combo;
