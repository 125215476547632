import { CardStackIcon, DashboardIcon,  } from '@radix-ui/react-icons';
import { ERoutes } from '../typescript/enums';

export const routes = [
    {
        label: "Dashboard",
        Icon: DashboardIcon,
        link: ERoutes.HOME,
    },
    {
        label: "Cards",
        Icon: CardStackIcon,
        link: ERoutes.CARDS,
    },
    {
        label: "Daily Bonus",
        Icon: CardStackIcon,
        link: ERoutes.DAILY,
    },
    {
        label: "Daily Tasks",
        Icon: CardStackIcon,
        link: ERoutes.TASKS,
    },
    {
        label: "Daily Chests",
        Icon: CardStackIcon,
        link: ERoutes.CHESTS,
    },
    {
        label: "Combo",
        Icon: CardStackIcon,
        link: ERoutes.COMBO,
    },
]
