import React, { FC, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { EditIcon, TrashIcon } from 'lucide-react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import { Input } from '@components/input';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { toast } from '@components/use-toast';
import { TCard, TDailyChest } from '@types';
import { ETaskType, EDailyChestType, ECardType } from '../../../typescript/enums';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from '@components/select';
import { apiCreateChest, apiUpdateChest } from '@api/chests';

interface IProps {
    onSuccess: () => void;
    data: TDailyChest;
    allCards: TCard[];
}

const defaultLoot = {
    currency: 'gton',
    amount: '0.1',
    chance: 0
};

const currencies = [
    { label: 'GTON (internal)', value: 'gton' },
    { label: 'TON', value: 'ton' },
    { label: 'ETH', value: 'eth' },
    { label: 'BTC', value: 'btc' }
];

const UpdateChestModal: FC<IProps> = ({ onSuccess, data, allCards }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [percentageError, setPercentageError] = useState(false);
    const form = useForm<TDailyChest>({
        mode: 'onChange',
        defaultValues: {
            title: {
                uk: '',
                ru: '',
                en: ''
            },
            description: {
                uk: '',
                ru: '',
                en: ''
            },
            task: {
                type: ETaskType.LEVEL,
                value: 0,
                cardId: ''
            },
            loot: [defaultLoot],
            type: EDailyChestType.DAILY
        }
    });

    const taskType = useWatch({ name: 'task.type', control: form.control });
    const chestType = useWatch({ name: 'type', control: form.control });
    const loot = useWatch({ name: 'loot', control: form.control });

    useEffect(() => {
        const fullChance = loot.reduce((a, item) => {
            return a + Number(item.chance);
        }, 0);

        if (fullChance !== 100) {
            setPercentageError(true);
        } else {
            setPercentageError(false);
        }
    }, [loot]);

    const { append, remove, fields } = useFieldArray({ name: 'loot', control: form.control });

    const { handleRequest } = useApiMutation({
        method: apiUpdateChest,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Chest has been successfully updated`
            });
            setIsOpen(false);
            onSuccess();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    const { handleRequest: handleCreate } = useApiMutation({
        method: apiCreateChest,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Chest has been successfully updated`
            });
            setIsOpen(false);
            onSuccess();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    const onSubmit = () => {
        const { id, loot, task, ...values } = form.getValues();
        if (
            data.type === 3 &&
            (task.type !== data.task.type ||
                task.value !== data.task.value ||
                task?.cardId !== data.task?.cardId)
        ) {
            handleCreate({
                ...values,
                task: {
                    ...task,
                    value: task.value && Number(task.value)
                },
                loot: loot.map((item) => ({
                    ...item,
                    amount: Number(item.amount),
                    chance: Number(item.chance)
                }))
            });
        }
        handleRequest({
            ...values,
            id,
            task: !task.type
                ? undefined
                : {
                      ...task,
                      value: task.value && Number(task.value)
                  },
            loot: loot.map((item) => ({
                ...item,
                amount: Number(item.amount),
                chance: Number(item.chance)
            }))
        });
    };

    useEffect(() => {
        if (data) {
            form.reset(data);
        }
    }, [data]);

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 edit w-full`} variant="ghost">
                    <EditIcon />
                </Button>
            </DialogTrigger>
            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Update Chest</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <div className="flex flex-col gap-3">
                            <div className="flex gap-2">
                                <div className="w-full">
                                    <FormField
                                        name="title.uk"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name UA</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.uk"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description UA</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.ru"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name RU</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.ru"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description RU</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.en"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name EN</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="description.en"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Description EN</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Description" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {chestType !== EDailyChestType.DAILY && (
                                <div className="flex gap-2">
                                    <FormField
                                        name="task.type"
                                        render={({ field }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Type</FormLabel>
                                                <FormControl>
                                                    <Select
                                                        value={field.value}
                                                        onValueChange={field.onChange}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Type" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectItem value={ETaskType.LEVEL}>
                                                                    User level
                                                                </SelectItem>
                                                                <SelectItem value={ETaskType.CARD}>
                                                                    Card level
                                                                </SelectItem>
                                                                <SelectItem
                                                                    value={ETaskType.REFERRALS}
                                                                >
                                                                    Referrals count
                                                                </SelectItem>
                                                                <SelectItem
                                                                    value={ETaskType.NEW_REFERRALS}
                                                                >
                                                                    New Referrals count
                                                                </SelectItem>
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </FormControl>
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                    <FormField
                                        name="task.value"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Value</FormLabel>
                                                <FormControl>
                                                    <Input {...field} type="number" />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: { value: true, message: 'Required field' }
                                        }}
                                    />
                                    {taskType === ETaskType.CARD && (
                                        <FormField
                                            name="task.cardId"
                                            render={({ field }) => (
                                                <FormItem className="w-full">
                                                    <FormLabel>Card</FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            value={field.value}
                                                            onValueChange={field.onChange}
                                                        >
                                                            <SelectTrigger>
                                                                <SelectValue placeholder="Card name" />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectLabel>
                                                                        Market
                                                                    </SelectLabel>
                                                                    {allCards
                                                                        .filter(
                                                                            (item) =>
                                                                                item.category ===
                                                                                ECardType.MARKET
                                                                        )
                                                                        .map((item) => (
                                                                            <SelectItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title?.uk} (
                                                                                {item.category})
                                                                            </SelectItem>
                                                                        ))}
                                                                </SelectGroup>
                                                                <SelectGroup>
                                                                    <SelectLabel>Team</SelectLabel>
                                                                    {allCards
                                                                        .filter(
                                                                            (item) =>
                                                                                item.category ===
                                                                                ECardType.TEAM
                                                                        )
                                                                        .map((item) => (
                                                                            <SelectItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title?.uk} (
                                                                                {item.category})
                                                                            </SelectItem>
                                                                        ))}
                                                                </SelectGroup>
                                                                <SelectGroup>
                                                                    <SelectLabel>Legal</SelectLabel>
                                                                    {allCards
                                                                        .filter(
                                                                            (item) =>
                                                                                item.category ===
                                                                                ECardType.LEGAL
                                                                        )
                                                                        .map((item) => (
                                                                            <SelectItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title?.uk} (
                                                                                {item.category})
                                                                            </SelectItem>
                                                                        ))}
                                                                </SelectGroup>
                                                                <SelectGroup>
                                                                    <SelectLabel>
                                                                        Special
                                                                    </SelectLabel>
                                                                    {allCards
                                                                        .filter(
                                                                            (item) =>
                                                                                item.category ===
                                                                                ECardType.SPECIAL
                                                                        )
                                                                        .map((item) => (
                                                                            <SelectItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title?.uk} (
                                                                                {item.category})
                                                                            </SelectItem>
                                                                        ))}
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                            rules={{
                                                required: { value: true, message: 'Required field' }
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            <div className="flex flex-col gap-2">
                                Loot
                                {fields.map((item, i) => {
                                    return (
                                        <div className={`flex flex-col gap-2 pt-3 pb-3`}>
                                            <div
                                                key={item.id}
                                                className="flex gap-4 items-end pl-4"
                                            >
                                                <FormField
                                                    name={`loot.${i}.currency`}
                                                    render={({ field }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Currency</FormLabel>
                                                            <FormControl>
                                                                <Select
                                                                    value={field.value}
                                                                    onValueChange={field.onChange}
                                                                >
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Currency" />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            {currencies.map(
                                                                                (item) => (
                                                                                    <SelectItem
                                                                                        key={
                                                                                            item.value
                                                                                        }
                                                                                        value={
                                                                                            item.value
                                                                                        }
                                                                                    >
                                                                                        {item.label}
                                                                                    </SelectItem>
                                                                                )
                                                                            )}
                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name={`loot.${i}.amount`}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Amount</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Price"
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <FormField
                                                    name={`loot.${i}.chance`}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <FormItem className="w-full">
                                                            <FormLabel>Chance</FormLabel>
                                                            <FormControl>
                                                                <div className="flex items-center gap-1 relative">
                                                                    <p className="absolute right-2">
                                                                        %
                                                                    </p>
                                                                    <Input
                                                                        {...field}
                                                                        onChange={(e) => {
                                                                            if (
                                                                                /^\d+(\.\d{1,2})?$/.test(
                                                                                    e.target.value
                                                                                )
                                                                            ) {
                                                                                const fullChance =
                                                                                    loot.reduce(
                                                                                        (
                                                                                            a,
                                                                                            item,
                                                                                            currentIndex
                                                                                        ) => {
                                                                                            if (
                                                                                                currentIndex !==
                                                                                                i
                                                                                            ) {
                                                                                                return (
                                                                                                    a +
                                                                                                    Number(
                                                                                                        item.chance
                                                                                                    )
                                                                                                );
                                                                                            } else {
                                                                                                return a;
                                                                                            }
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                if (
                                                                                    Number(
                                                                                        (
                                                                                            fullChance +
                                                                                            Number(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        ).toFixed(2)
                                                                                    ) <= 100 &&
                                                                                    Number(
                                                                                        e.target
                                                                                            .value
                                                                                    ) > 0
                                                                                ) {
                                                                                    field.onChange(
                                                                                        e
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                field.onChange(e);
                                                                            }
                                                                        }}
                                                                        className="pr-6"
                                                                    />
                                                                </div>
                                                            </FormControl>
                                                            {error?.message && (
                                                                <FormDescription>
                                                                    Invalid field
                                                                </FormDescription>
                                                            )}
                                                        </FormItem>
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Required field'
                                                        }
                                                    }}
                                                />
                                                <Button variant="ghost" onClick={() => remove(i)}>
                                                    <TrashIcon color="red" />
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                                <Button
                                    className="mt-2 ml-4"
                                    onClick={() => {
                                        append(defaultLoot);
                                    }}
                                >
                                    Add loot
                                </Button>
                            </div>
                        </div>
                    </Form>
                    <DialogFooter className="flex items-center gap-4" style={{ marginTop: 'auto' }}>
                        {percentageError && (
                            <p className="text-red-600">Percentage sum must be 100</p>
                        )}
                        <Button disabled={percentageError} onClick={onSubmit}>
                            Save
                        </Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default UpdateChestModal;
