import React from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from '@components/card';
import { prettifyNumber } from '@lib/utils';

interface StatisticCardsProps {
    data:
        | {
              currentDayUsers?: number;
              previousDayUsers?: number;
              currentMonthUsers?: number;
              previousMonthUsers?: number;
              totalCountUsers?: number;
          }
        | undefined;
}

const StatisticCards: React.FC<StatisticCardsProps> = ({ data }) => {
    return (
        <div className="flex items-center gap-6 flex-col md:flex-row">
            <Card className="w-[100%] md:w-max">
                <CardHeader>
                    <CardDescription>Today</CardDescription>
                    <CardTitle>{prettifyNumber(data?.currentDayUsers || 0)}</CardTitle>
                </CardHeader>
            </Card>
            <Card className="w-[100%] md:w-max">
                <CardHeader>
                    <CardDescription>Yesterday</CardDescription>
                    <CardTitle>{prettifyNumber(data?.previousDayUsers || 0)}</CardTitle>
                </CardHeader>
            </Card>
            <Card className="w-[100%] md:w-max">
                <CardHeader>
                    <CardDescription>Current Month</CardDescription>
                    <CardTitle>{prettifyNumber(data?.currentMonthUsers || 0)}</CardTitle>
                </CardHeader>
            </Card>
            <Card className="w-[100%] md:w-max">
                <CardHeader>
                    <CardDescription>Previous Month</CardDescription>
                    <CardTitle>{prettifyNumber(data?.previousMonthUsers || 0)}</CardTitle>
                </CardHeader>
            </Card>
            <Card className="w-[100%] md:w-max">
                <CardHeader>
                    <CardDescription>Total</CardDescription>
                    <CardTitle>{prettifyNumber(data?.totalCountUsers || 0)}</CardTitle>
                </CardHeader>
            </Card>
        </div>
    );
};

export default StatisticCards;
