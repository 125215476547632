import React from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from '@components/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@components/table';
import { Button } from '@components/button';
import { ArrowUp } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@components/avatar';
import { TUser } from '@types';
import UserModal from '../../../components/modals/user';

interface UserTableProps {
    usersListData: {
        data: {
            users: TUser[];
        };
        metadata: {
            totalPages: number;
        };
    };
    pagination: {
        page: string;
        pageSize: string;
        sort: string;
    };
    handleChangeSort: (sort: string) => void;
}

const UserTable: React.FC<UserTableProps> = ({ usersListData, pagination, handleChangeSort }) => {
    return (
        <Table className="w-full">
            <TableHeader>
                <TableRow>
                    <TableHead className="w-[100px]">Number</TableHead>
                    <TableHead>Username</TableHead>
                    <TableHead>Is Premium</TableHead>
                    <TableHead className="text-center">
                        <Button
                            onClick={() => handleChangeSort('totalTaps')}
                            variant="ghost"
                            className="w-full flex items-center gap-2 cursor-pointer"
                        >
                            Total Taps
                            <ArrowUp
                                className={`ml-2 h-4 w-4 ${pagination.sort === 'totalTaps' && 'rotate-180'}`}
                            />
                        </Button>
                    </TableHead>
                    <TableHead className="text-center">
                        <Button
                            onClick={() => handleChangeSort('referralsCount')}
                            variant="ghost"
                            className="w-full flex items-center gap-2 cursor-pointer"
                        >
                            Referrals{' '}
                            <ArrowUp
                                className={`ml-2 h-4 w-4 ${pagination.sort === 'referralsCount' && 'rotate-180'}`}
                            />
                        </Button>
                    </TableHead>
                    <TableHead className="text-center">
                        <Button
                            onClick={() => handleChangeSort('level')}
                            variant="ghost"
                            className="w-full flex items-center gap-2 cursor-pointer"
                        >
                            Level{' '}
                            <ArrowUp
                                className={`ml-2 h-4 w-4 ${pagination.sort === 'level' && 'rotate-180'}`}
                            />
                        </Button>
                    </TableHead>
                    <TableHead className="text-center">Actions</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {usersListData?.data.users.map((user, index) => {
                    return (
                        <TableRow key={user._id}>
                            <TableCell className="font-medium">
                                {Number(pagination.page) * Number(pagination.pageSize) + index + 1}
                            </TableCell>
                            <TableCell className="flex gap-2 items-center">
                                <Avatar>
                                    <AvatarImage src={user.photo_url || ''} />
                                    <AvatarFallback>{user.username.slice(0, 1)}</AvatarFallback>
                                </Avatar>
                                {user.username}
                            </TableCell>
                            <TableCell className="text-center">
                                <Card className="w-max p-3 flex flex-col gap-1">
                                    <CardDescription>
                                        <CardTitle
                                            className={
                                                user.isPremium ? 'text-green-600' : 'text-red-600'
                                            }
                                        >
                                            {user.isPremium ? 'Yes' : 'No'}
                                        </CardTitle>
                                    </CardDescription>
                                </Card>
                            </TableCell>
                            <TableCell className="text-center">{user.totalTaps}</TableCell>
                            <TableCell className="text-center">{user.referralsCount}</TableCell>
                            <TableCell className="text-center">{user.level}</TableCell>
                            <TableCell className="text-center">
                                <UserModal user={user} />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default UserTable;
