import React, { FC, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { TrashIcon } from 'lucide-react';

interface IProps {
    title?: string;
    content?: string;
    onSuccess: () => void;
}

const DeleteCardModal: FC<IProps> = ({ title, content, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false);

    function handleCloseModal() {
        setIsOpen(false);
    }

    function handleSubmitModal() {
        onSuccess();
        handleCloseModal();
    }

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className="w-full gap-2" variant="ghost">
                    <TrashIcon color="red" />
                </Button>
            </DialogTrigger>
            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>{title || 'Delete current card'}</DialogTitle>
                    </DialogHeader>
                    {content || 'Are you sure you want to delete that card?'}
                    <DialogFooter style={{ marginTop: 'auto' }}>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            no
                        </Button>
                        <Button variant="destructive" onClick={handleSubmitModal}>
                            yes
                        </Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default DeleteCardModal;
