import React, {FC, useState} from 'react';
import {ECardType} from "../../../typescript/enums";
//Components
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import {Button} from '@components/button';
import {EditIcon, PlusIcon} from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@components/select";


//Api
import {useApiQuery} from "@hooks/api/useApiQuery";
import {apiGetAllCards} from "@api/cards";
import {TCard, TComboCard} from "@types";
import {string} from "zod";
import {getComboFreeCards, updateComboLoot} from "@api/combo";
import {toast} from "@components/use-toast";

interface IProps {
    cardId: string
    cardTitle: string
    data: any
    setUpd:()=>void
}

const ComboUpdateCard: FC<IProps> = ({cardId, cardTitle, data,setUpd}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [id, setId] = useState<undefined | string>()

    const cards = useApiQuery({
        method: getComboFreeCards,
        isInitialRequest: true
    });


    const onSubmit = async () => {
        if (!id) return;

        const newCard = cards?.data?.find((el: any) => el._id === id);
        if (!newCard) return;

        const cardIndex = data.cards.findIndex((card: any) => card._id === cardId);
        if (cardIndex === -1) return;

        const updatedComboCards = [...data.cards].map((el: TComboCard) => el._id);
        updatedComboCards[cardIndex] = newCard._id;

        const res = await updateComboLoot(data._id, {cards: updatedComboCards});

        if (res.status === 200) {
            toast({
                variant: "default",
                description: `Combo Successfully Changed`
            });
            setUpd()
            setIsOpen(false)
        } else {
            toast({
                variant: "destructive",
                description: `Something went wrong`
            });
        }
    };


    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 w-full`} variant="outline">
                    <EditIcon className={"flex-shrink-0"}/>Combo Update Card
                </Button>
            </DialogTrigger>

            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px]  min-h-[40vh]  overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Add new task</DialogTitle>
                    </DialogHeader>


                    <div>
                        <Select
                            value={id}
                            onValueChange={(el) => setId(el)}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Card name"/>
                            </SelectTrigger>

                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>
                                        Select card
                                    </SelectLabel>
                                    {typeof cards !== "undefined" && cards?.data?.map((item: TComboCard) => (
                                        <SelectItem
                                            key={item._id}
                                            value={item._id}
                                        >
                                            {item.title?.en}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>


                    <DialogFooter style={{marginTop: 'auto'}}>
                        <Button onClick={onSubmit}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ComboUpdateCard;
