import axios, { AxiosResponse } from 'axios';
import {ILoginRequest, IUpdateCombo} from '../typescript/requests';
import {ICombosResponse, ILoginResponse, IRefreshResponse} from '../typescript/responses';

export const getCombos = (): Promise<AxiosResponse<ICombosResponse>> => {
    return axios.get('/settings/combos');
}

export const getComboFreeCards = (): Promise<AxiosResponse<any>> => {
    return axios.get('/settings/combos/available-cards');
}


export const updateComboLoot = (id:string, data:IUpdateCombo): Promise<AxiosResponse<any>> => {
    return axios.put(`/settings/combos/${id}`, data);
}


