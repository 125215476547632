import React from 'react';
import Sidebar from '@sections/sidebar';
import { SideBarMobile } from '@sections/sidebar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className="wrapper flex min-h-[100vh] flex-col md:flex-row">
            <Sidebar />
            <SideBarMobile />
            <div className="p-8 w-[100%] md:w-[calc(100%-256px)]">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
